let config = {
  // default country set
  DefaultCountrySelectedInMobile: "in",
  // default language
  DefaultLanguage: "en",

  //google Places key
  // YOUR_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_MAP_API_KEY,
  YOUR_GOOGLE_MAPS_API_KEY: "AIzaSyBjktURASMOvY3Gda-6e7sFrDfHEkwO7cI",
};

export default config;
